exports.env = {
  api: {
    endpoint: {
      protocol: window._env_.REACT_APP_BACKEND_PROTOCOL || "http",
      host: window._env_.REACT_APP_BACKEND_HOST || "localhost",
      port: window._env_.REACT_APP_BACKEND_PORT || 5000,
      hostR: window._env_.R_CODE_BACKEND_HOST || "localhost",
      portR: window._env_.R_CODE_BACKEND_PORT || 8000,
    },
  },
};
