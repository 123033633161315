export const RESET_GLOBAL = '@@global/RESET_GLOBAL'
export const UPDATE_USER = '@@global/UPDATE_USER'
export const UPDATE_AUTH = '@@global/UPDATE_AUTH'
export const UPDATE_ERROR = '@@global/UPDATE_ERROR'
export const UPDATE_MODE = '@@global/UPDATE_MODE'
export const UPDATE_DEFAULT_NX = '@@global/UPDATE_DEFAULT_NX'

export const UPDATE_AVAILABLE_CHAMPIONSHIPS =
  '@@global/UPDATE_AVAILABLE_CHAMPIONSHIPS'
export const UPDATE_AVAILABLE_CHAMPIONSHIP_NAMES =
  '@@global/UPDATE_AVAILABLE_CHAMPIONSHIP_NAMES'
export const UPDATE_SELECTED_CHAMPIONSHIP =
  '@@global/UPDATE_SELECTED_CHAMPIONSHIP'
