import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

import { colours } from "../utils/colours";

const Bonus = ({
  bonusA,
  bonusB,
  selectedTeamA,
  selectedTeamB,
  open,
  handleClose,
  bonusAHandler,
  bonusBHandler,
}) => {
  const GreenCheckbox = withStyles({
    root: {
      color: colours.greyDark,
      "&$checked": {
        color: colours.mainDark,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const bonuses = ["Battuta", "Ricezione", "Attacco", "Nessuno"].map((b, i) => (
    <Grid item xs key={i}>
      <center>
        <b>{b}</b>
      </center>
    </Grid>
  ));

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-describedby="bonus"
    >
      <DialogContent>
        <Grid container item xs alignItems="center">
          <Grid item xs>
            <center>
              <h3>{selectedTeamA || "Squadra A"}</h3>
            </center>
          </Grid>
          <Grid item xs>
            <center>
              <h3>{selectedTeamB || "Squadra B"}</h3>
            </center>
          </Grid>
        </Grid>
        &nbsp;
        <Grid container item xs>
          <Grid container item xs direction="column" spacing={2}>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusA === "ser"}
                  onChange={() => bonusAHandler("ser")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusA === "rec"}
                  onChange={() => bonusAHandler("rec")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusA === "att"}
                  onChange={() => bonusAHandler("att")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusA === "no"}
                  onChange={() => bonusAHandler("no")}
                />
              </center>
            </Grid>
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            {bonuses}
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusB === "ser"}
                  onChange={() => bonusBHandler("ser")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusB === "rec"}
                  onChange={() => bonusBHandler("rec")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusB === "att"}
                  onChange={() => bonusBHandler("att")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={bonusB === "no"}
                  onChange={() => bonusBHandler("no")}
                />
              </center>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  bonusA: state.simulation.bonusA,
  bonusB: state.simulation.bonusB,
  selectedTeamA: state.players.selectedTeamA,
  selectedTeamB: state.players.selectedTeamB,
});

const ConnectedBonus = connect(mapStateToProps)(Bonus);

export default ConnectedBonus;
