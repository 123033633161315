import React from "react";
import { useDispatch, connect } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

import {
  UPDATE_TEAM_A_SUBS_SERVICE,
  UPDATE_TEAM_B_SUBS_SERVICE,
  UPDATE_TEAM_A_SUBS_DIAGONAL,
  UPDATE_TEAM_B_SUBS_DIAGONAL,
  RESET_TEAM_A_SUBS_SERVICE,
  RESET_TEAM_B_SUBS_SERVICE,
  RESET_TEAM_A_SUBS_DIAGONAL,
  RESET_TEAM_B_SUBS_DIAGONAL,
} from "../container/simulation/types";

import { colours } from "../utils/colours";

const Substitution = ({
  selectedChampionship,
  teamAsubsService,
  teamBsubsService,
  teamAsubsDiagonal,
  teamBsubsDiagonal,
  availablePlayers,
  availableSetters,
  availableOpposites,
  selectedSetterA,
  selectedOppositeA,
  selectedMiddle1A,
  selectedMiddle2A,
  selectedHitter1A,
  selectedHitter2A,
  selectedSetterB,
  selectedOppositeB,
  selectedMiddle1B,
  selectedMiddle2B,
  selectedHitter1B,
  selectedHitter2B,
  selectedTeamA,
  selectedTeamB,
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const GreenCheckbox = withStyles({
    root: {
      color: colours.greyDark,
      "&$checked": {
        color: colours.mainDark,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const subAserOutHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_SERVICE,
      payload: {
        ...teamAsubsService,
        out: value,
      },
    });
  };

  const subAserInHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_SERVICE,
      payload: {
        ...teamAsubsService,
        in: value,
      },
    });
  };

  const subAserWhenHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_SERVICE,
      payload: {
        ...teamAsubsService,
        when: value,
      },
    });
  };

  const subBserOutHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_SERVICE,
      payload: {
        ...teamBsubsService,
        out: value,
      },
    });
  };

  const subBserInHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_SERVICE,
      payload: {
        ...teamBsubsService,
        in: value,
      },
    });
  };

  const subBserWhenHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_SERVICE,
      payload: {
        ...teamBsubsService,
        when: value,
      },
    });
  };

  const subAdiaOut1Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: {
        ...teamAsubsDiagonal,
        out1: value,
      },
    });
  };

  const subAdiaOut2Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: {
        ...teamAsubsDiagonal,
        out2: value,
      },
    });
  };

  const subAdiaIn1Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: {
        ...teamAsubsDiagonal,
        in1: value,
      },
    });
  };

  const subAdiaIn2Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: {
        ...teamAsubsDiagonal,
        in2: value,
      },
    });
  };

  const subAdiaWhenHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_A_SUBS_DIAGONAL,
      payload: {
        ...teamAsubsDiagonal,
        when: value,
      },
    });
  };

  const subBdiaOut1Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: {
        ...teamBsubsDiagonal,
        out1: value,
      },
    });
  };

  const subBdiaOut2Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: {
        ...teamBsubsDiagonal,
        out2: value,
      },
    });
  };

  const subBdiaIn1Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: {
        ...teamBsubsDiagonal,
        in1: value,
      },
    });
  };

  const subBdiaIn2Handler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: {
        ...teamBsubsDiagonal,
        in2: value,
      },
    });
  };

  const subBdiaWhenHandler = (value) => {
    dispatch({
      type: UPDATE_TEAM_B_SUBS_DIAGONAL,
      payload: {
        ...teamBsubsDiagonal,
        when: value,
      },
    });
  };

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        <Grid container item xs direction="column" spacing={5}>
          <Grid container item xs>
            <Grid item xs>
              <center>
                <h3>{selectedTeamA || "Squadra A"}</h3>
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <h3>{selectedTeamB || "Squadra B"}</h3>
              </center>
            </Grid>
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            <Grid item xs>
              <center>
                <b>Cambio in battuta</b>
              </center>
            </Grid>
            <Grid container item xs spacing={3}>
              <Grid container item xs direction="column" spacing={2}>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!![
                          selectedSetterA,
                          selectedOppositeA,
                          selectedMiddle1A,
                          selectedMiddle2A,
                          selectedHitter1A,
                          selectedHitter2A,
                        ] &&
                          [
                            selectedSetterA,
                            selectedOppositeA,
                            selectedMiddle1A,
                            selectedMiddle2A,
                            selectedHitter1A,
                            selectedHitter2A,
                          ].filter((s) => !!s)) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamAsubsService.out || null}
                      onChange={(_, value) => subAserOutHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Esce"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!availablePlayers[selectedChampionship] &&
                          availablePlayers[selectedChampionship]
                            .filter((s) => !!s)
                            .filter((s) =>
                              !!selectedTeamA ? s.team === selectedTeamA : true
                            )) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamAsubsService.in || null}
                      onChange={(_, value) => subAserInHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Entra"} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={["12", "14", "16", "18", "20", "22"]}
                      value={teamAsubsService.when || null}
                      onChange={(_, value) => subAserWhenHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Dopo il"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <center>
                      <GreenCheckbox
                        checked={
                          !!teamAsubsService.out &&
                          !!teamAsubsService.in &&
                          !!teamAsubsService.when
                        }
                        onChange={() =>
                          dispatch({ type: RESET_TEAM_A_SUBS_SERVICE })
                        }
                      />
                    </center>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs direction="column" spacing={3}>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!![
                          selectedSetterB,
                          selectedOppositeB,
                          selectedMiddle1B,
                          selectedMiddle2B,
                          selectedHitter1B,
                          selectedHitter2B,
                        ] &&
                          [
                            selectedSetterB,
                            selectedOppositeB,
                            selectedMiddle1B,
                            selectedMiddle2B,
                            selectedHitter1B,
                            selectedHitter2B,
                          ].filter((s) => !!s)) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamBsubsService.out || null}
                      onChange={(_, value) => subBserOutHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Esce"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!availablePlayers[selectedChampionship] &&
                          availablePlayers[selectedChampionship]
                            .filter((s) => !!s)
                            .filter((s) =>
                              !!selectedTeamB ? s.team === selectedTeamB : true
                            )) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamBsubsService.in || null}
                      onChange={(_, value) => subBserInHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Entra"} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={["12", "14", "16", "18", "20", "22"]}
                      value={teamBsubsService.when || null}
                      onChange={(_, value) => subBserWhenHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Dopo il"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <center>
                      <GreenCheckbox
                        checked={
                          !!teamBsubsService.out &&
                          !!teamBsubsService.in &&
                          !!teamBsubsService.when
                        }
                        onChange={() =>
                          dispatch({ type: RESET_TEAM_B_SUBS_SERVICE })
                        }
                      />
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          &nbsp;
          <Grid container item xs direction="column" spacing={2}>
            <Grid item xs>
              <center>
                <b>Doppio cambio</b>
              </center>
            </Grid>
            <Grid container item xs spacing={5}>
              <Grid container item xs direction="column" spacing={3}>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={(!!selectedSetterA && [selectedSetterA]) || []}
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamAsubsDiagonal.out1 || null}
                      onChange={(_, value) => subAdiaOut1Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Esce (P)"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!availableSetters[selectedChampionship] &&
                          availableSetters[selectedChampionship]
                            .filter((s) => !!s)
                            .filter((s) =>
                              !!selectedTeamA ? s.team === selectedTeamA : true
                            )) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamAsubsDiagonal.in1 || null}
                      onChange={(_, value) => subAdiaIn1Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Entra (P)"} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!selectedOppositeA && [selectedOppositeA]) || []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamAsubsDiagonal.out2 || null}
                      onChange={(_, value) => subAdiaOut2Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Esce (O)"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!availableOpposites[selectedChampionship] &&
                          availableOpposites[selectedChampionship]
                            .filter((s) => !!s)
                            .filter((s) =>
                              !!selectedTeamA ? s.team === selectedTeamA : true
                            )) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamAsubsDiagonal.in2 || null}
                      onChange={(_, value) => subAdiaIn2Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Entra (O)"} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={["12", "14", "16", "18", "20", "22"]}
                      value={teamAsubsDiagonal.when || null}
                      onChange={(_, value) => subAdiaWhenHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Dopo il"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <center>
                      <GreenCheckbox
                        checked={
                          !!teamAsubsDiagonal.out1 &&
                          !!teamAsubsDiagonal.out2 &&
                          !!teamAsubsDiagonal.in1 &&
                          !!teamAsubsDiagonal.in2 &&
                          !!teamAsubsDiagonal.when
                        }
                        onChange={() =>
                          dispatch({ type: RESET_TEAM_A_SUBS_DIAGONAL })
                        }
                      />
                    </center>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs direction="column" spacing={3}>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={(!!selectedSetterB && [selectedSetterB]) || []}
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamBsubsDiagonal.out1 || null}
                      onChange={(_, value) => subBdiaOut1Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Esce (P)"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!availableSetters[selectedChampionship] &&
                          availableSetters[selectedChampionship]
                            .filter((s) => !!s)
                            .filter((s) =>
                              !!selectedTeamB ? s.team === selectedTeamB : true
                            )) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamBsubsDiagonal.in1 || null}
                      onChange={(_, value) => subBdiaIn1Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Entra (P)"} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!selectedOppositeB && [selectedOppositeB]) || []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamBsubsDiagonal.out2 || null}
                      onChange={(_, value) => subBdiaOut2Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Esce (O)"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={
                        (!!availableOpposites[selectedChampionship] &&
                          availableOpposites[selectedChampionship]
                            .filter((s) => !!s)
                            .filter((s) =>
                              !!selectedTeamB ? s.team === selectedTeamB : true
                            )) ||
                        []
                      }
                      getOptionLabel={(op) => `${op.name} ${op.surname}`}
                      getOptionSelected={(op) => op.playerID}
                      value={teamBsubsDiagonal.in2 || null}
                      onChange={(_, value) => subBdiaIn2Handler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Entra (O)"} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs spacing={5}>
                  <Grid item xs>
                    <Autocomplete
                      fullWidth
                      options={["12", "14", "16", "18", "20", "22"]}
                      value={teamBsubsDiagonal.when || null}
                      onChange={(_, value) => subBdiaWhenHandler(value)}
                      renderInput={(params) => (
                        <TextField {...params} label={"Dopo il"} />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <center>
                      <GreenCheckbox
                        checked={
                          !!teamBsubsDiagonal.out1 &&
                          !!teamBsubsDiagonal.out2 &&
                          !!teamBsubsDiagonal.in1 &&
                          !!teamBsubsDiagonal.in2 &&
                          !!teamBsubsDiagonal.when
                        }
                        onChange={() =>
                          dispatch({ type: RESET_TEAM_B_SUBS_DIAGONAL })
                        }
                      />
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

Substitution.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  selectedChampionship: state.global.selectedChampionship,
  teamAsubsService: state.simulation.teamAsubsService,
  teamBsubsService: state.simulation.teamBsubsService,
  teamAsubsDiagonal: state.simulation.teamAsubsDiagonal,
  teamBsubsDiagonal: state.simulation.teamBsubsDiagonal,
  availablePlayers: state.players.availablePlayers,
  availableSetters: state.players.availableSetters,
  availableOpposites: state.players.availableOpposites,
  selectedSetterA: state.players.selectedSetterA,
  selectedOppositeA: state.players.selectedOppositeA,
  selectedMiddle1A: state.players.selectedMiddle1A,
  selectedMiddle2A: state.players.selectedMiddle2A,
  selectedHitter1A: state.players.selectedHitter1A,
  selectedHitter2A: state.players.selectedHitter2A,
  selectedSetterB: state.players.selectedSetterB,
  selectedOppositeB: state.players.selectedOppositeB,
  selectedMiddle1B: state.players.selectedMiddle1B,
  selectedMiddle2B: state.players.selectedMiddle2B,
  selectedHitter1B: state.players.selectedHitter1B,
  selectedHitter2B: state.players.selectedHitter2B,
  selectedTeamA: state.players.selectedTeamA,
  selectedTeamB: state.players.selectedTeamB,
});

const ConnectedSubstitution = connect(mapStateToProps)(Substitution);

export default ConnectedSubstitution;
