export const RESET_PLAYERS = "@@players/RESET_PLAYERS";
export const RESET_SELECTED = "@@players/RESET_SELECTED";

export const UPDATE_AVAILABLE_PLAYERS = "@@players/UPDATE_AVAILABLE_PLAYERS";
export const UPDATE_AVAILABLE_SETTERS = "@@players/UPDATE_AVAILABLE_SETTERS";
export const UPDATE_AVAILABLE_OPPOSITES =
  "@@players/UPDATE_AVAILABLE_OPPOSITES";
export const UPDATE_AVAILABLE_MIDDLES = "@@players/UPDATE_AVAILABLE_MIDDLES";
export const UPDATE_AVAILABLE_HITTERS = "@@players/UPDATE_AVAILABLE_HITTERS";
export const UPDATE_AVAILABLE_LIBEROS = "@@players/UPDATE_AVAILABLE_LIBEROS";

export const UPDATE_SELECTED_SETTER_A = "@@players/UPDATE_SELECTED_SETTER_A";
export const UPDATE_SELECTED_OPPOSITE_A =
  "@@players/UPDATE_SELECTED_OPPOSITE_A";
export const UPDATE_SELECTED_MIDDLE1_A = "@@players/UPDATE_SELECTED_MIDDLE1_A";
export const UPDATE_SELECTED_MIDDLE2_A = "@@players/UPDATE_SELECTED_MIDDLE2_A";
export const UPDATE_SELECTED_HITTER1_A = "@@players/UPDATE_SELECTED_HITTER1_A";
export const UPDATE_SELECTED_HITTER2_A = "@@players/UPDATE_SELECTED_HITTER2_A";
export const UPDATE_SELECTED_LIBERO_A = "@@players/UPDATE_SELECTED_LIBERO_A";

export const UPDATE_SELECTED_SETTER_B = "@@players/UPDATE_SELECTED_SETTER_B";
export const UPDATE_SELECTED_OPPOSITE_B =
  "@@players/UPDATE_SELECTED_OPPOSITE_B";
export const UPDATE_SELECTED_MIDDLE1_B = "@@players/UPDATE_SELECTED_MIDDLE1_B";
export const UPDATE_SELECTED_MIDDLE2_B = "@@players/UPDATE_SELECTED_MIDDLE2_B";
export const UPDATE_SELECTED_HITTER1_B = "@@players/UPDATE_SELECTED_HITTER1_B";
export const UPDATE_SELECTED_HITTER2_B = "@@players/UPDATE_SELECTED_HITTER2_B";
export const UPDATE_SELECTED_LIBERO_B = "@@players/UPDATE_SELECTED_LIBERO_B";

export const UPDATE_AVAILABLE_TEAMS = "@@players/UPDATE_AVAILABLE_TEAMS";
export const UPDATE_SELECTED_TEAM_A = "@@players/UPDATE_SELECTED_TEAM_A";
export const UPDATE_SELECTED_TEAM_B = "@@players/UPDATE_SELECTED_TEAM_B";
