import React from 'react'
import Grid from '@material-ui/core/Grid'

import TopColor from './TopColor'

const Header = ({ MinWidth = '100%', title = 'Prometeo' }) => {
  return (
    <Grid container item alignItems='center' style={{ minWidth: MinWidth }}>
      <Grid item xs>
        <center>
          <h2 style={{ margin: 10 }}>{title}</h2>
        </center>
      </Grid>
      <TopColor />
    </Grid>
  )
}

export default Header
