import {
  RESET_DASHBOARD,
  UPDATE_SIMULATIONS_INPUTS,
  UPDATE_SIMULATIONS_OUTPUTS,
} from "./types";

const INIT_STATE = {
  simulationsInputs: [],
  simulationsOutputs: [],
};

export const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_DASHBOARD:
      return INIT_STATE;
    case UPDATE_SIMULATIONS_INPUTS:
      return { ...state, simulationsInputs: action.payload };
    case UPDATE_SIMULATIONS_OUTPUTS:
      return { ...state, simulationsOutputs: action.payload };
    default:
      return state;
  }
};
