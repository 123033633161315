import React from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const SubsLog = ({
  selectedTeamA,
  selectedTeamB,
  subsLog,
  open,
  handleClose,
}) => {
  const colNames = [
    selectedTeamA || "Squadra A",
    "Set",
    "Punteggio",
    selectedTeamB || "Squadra B",
  ];

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-describedby="subsLog"
    >
      <DialogContent>
        <Table id="subsLog" size="small">
          <TableHead>
            <TableRow>
              {colNames.map((c, i) => (
                <TableCell
                  key={i}
                  align="center"
                  style={{ borderBottom: "1px solid" }}
                >
                  <font>
                    <b>{c}</b>
                  </font>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {subsLog &&
              subsLog.length > 0 &&
              subsLog.map((l, i) => (
                <TableRow key={i}>
                  <TableCell align="center" style={{ width: 500 }}>
                    <font>
                      {l["Team"] === "TeamA" ? l["In"] + " > " + l["Out"] : ""}
                    </font>
                  </TableCell>
                  <TableCell align="center" style={{ width: 100 }}>
                    <font>{l["Set"] + "° set"}</font>
                  </TableCell>
                  <TableCell align="center" style={{ width: 100 }}>
                    <font>{l["Score"]}</font>
                  </TableCell>
                  <TableCell align="center" style={{ width: 500 }}>
                    <font>
                      {l["Team"] === "TeamB" ? l["In"] + " > " + l["Out"] : ""}
                    </font>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  selectedTeamA: state.players.selectedTeamA,
  selectedTeamB: state.players.selectedTeamB,
});

const ConnectedSubsLog = connect(mapStateToProps)(SubsLog);

export default ConnectedSubsLog;
