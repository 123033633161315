import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const DrawingSelection = ({
  selectedTeamA,
  selectedTeamB,
  servesA,
  servesAHandler,
}) => {
  return (
    <Grid container item xs direction="row" alignItems="center">
      <Grid item xs>
        <center>
          <Tooltip title="Prima squadra a battere nel primo set">
            <ButtonGroup size="large">
              <Button
                variant={servesA ? "contained" : "text"}
                onClick={servesAHandler}
              >
                Battuta {selectedTeamA || "Squadra A"}
              </Button>
              <Button
                variant={!servesA ? "contained" : "text"}
                onClick={servesAHandler}
              >
                Battuta {selectedTeamB || "Squadra B"}
              </Button>
            </ButtonGroup>
          </Tooltip>
        </center>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  selectedTeamA: state.players.selectedTeamA,
  selectedTeamB: state.players.selectedTeamB,
  servesA: state.simulation.servesA,
});

const ConnectedDrawingSelection = connect(mapStateToProps)(DrawingSelection);

export default ConnectedDrawingSelection;
