import styled from "styled-components";
import Button from "@material-ui/core/Button";

import { colours } from "../utils/colours";

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => props.buttonwidth || 300}px;
    height: ${(props) => props.buttonheight || 80}px;
    padding: ${(props) => props.textpadding || 0}px;
    color: ${(props) => props.textcolour || colours.black};
    font-size: ${(props) => props.textsize || 1}em;
    font-weight: ${(props) => (props.textbold ? "bold" : "")};
    border: ${(props) => props.borderwidth || 1}px solid
      ${(props) => props.bordercolour || colours.black};
    background: ${(props) => props.backgroundcolour || ""};
    &:hover {
      background: ${(props) => props.hovercolour || props.backgroundcolour};
    }
    &:focus {
      background: ${(props) => props.focuscolour || props.backgroundcolour};
    }
  }
`;
