import { combineReducers } from "redux";

import { globalReducer } from "../container/global/reducer";
import { dashboardReducer } from "../container/dashboard/reducer";
import { simulationReducer } from "../container/simulation/reducer";
import { playersReducer } from "../container/players/reducer";

export const rootReducers = combineReducers({
  global: globalReducer,
  dashboard: dashboardReducer,
  simulation: simulationReducer,
  players: playersReducer,
});
