export const RESET_SIMULATION = "@@simulation/RESET_SIMULATION";
export const UPDATE_SIMULATIONID = "@@simulation/UPDATE_SIMULATIONID";
export const UPDATE_DESCRIPTION = "@@simulation/UPDATE_DESCRIPTION";
export const UPDATE_STATUS = "@@simulation/UPDATE_STATUS";

export const UPDATE_NX = "@@simulation/UPDATE_NX";
export const UPDATE_DIVIDE_BY_ROLE = "@@simulation/UPDATE_DIVIDE_BY_ROLE";
export const UPDATE_IS_PLAYABLE = "@@simulation/UPDATE_IS_PLAYABLE";

export const UPDATE_RISULTATO = "@@simulation/UPDATE_RISULTATO";
export const UPDATE_PARZIALI = "@@simulation/UPDATE_PARZIALI";
export const UPDATE_TABELLINO = "@@simulation/UPDATE_TABELLINO";
export const UPDATE_SUBS_LOG = "@@simulation/UPDATE_SUBS_LOG";

export const UPDATE_SERVES_A = "@@simulation/UPDATE_SERVES_A";

export const UPDATE_TEAM_A_ROTS = "@@simulation/UPDATE_TEAM_A_ROTS";
export const UPDATE_TEAM_B_ROTS = "@@simulation/UPDATE_TEAM_B_ROTS";
export const UPDATE_TEAM_A_BONUS = "@@simulation/UPDATE_TEAM_A_BONUS";
export const UPDATE_TEAM_B_BONUS = "@@simulation/UPDATE_TEAM_B_BONUS";

export const UPDATE_TEAM_A_TACTICS_VARIABLES =
  "@@simulation/UPDATE_TEAM_A_TACTICS_VARIABLES";
export const UPDATE_TEAM_A_TACTICS_VALUES =
  "@@simulation/UPDATE_TEAM_A_TACTICS_VALUES";
export const UPDATE_TEAM_B_TACTICS_VARIABLES =
  "@@simulation/UPDATE_TEAM_B_TACTICS_VARIABLES";
export const UPDATE_TEAM_B_TACTICS_VALUES =
  "@@simulation/UPDATE_TEAM_B_TACTICS_VALUES";

export const UPDATE_TEAM_A_SUBS_SERVICE =
  "@@simulation/UPDATE_TEAM_A_SUBS_SERVICE";
export const UPDATE_TEAM_B_SUBS_SERVICE =
  "@@simulation/UPDATE_TEAM_B_SUBS_SERVICE";
export const UPDATE_TEAM_A_SUBS_DIAGONAL =
  "@@simulation/UPDATE_TEAM_A_SUBS_DIAGONAL";
export const UPDATE_TEAM_B_SUBS_DIAGONAL =
  "@@simulation/UPDATE_TEAM_B_SUBS_DIAGONAL";
export const RESET_TEAM_A_SUBS_SERVICE =
  "@@simulation/RESET_TEAM_A_SUBS_SERVICE";
export const RESET_TEAM_B_SUBS_SERVICE =
  "@@simulation/RESET_TEAM_B_SUBS_SERVICE";
export const RESET_TEAM_A_SUBS_DIAGONAL =
  "@@simulation/RESET_TEAM_A_SUBS_DIAGONAL";
export const RESET_TEAM_B_SUBS_DIAGONAL =
  "@@simulation/RESET_TEAM_B_SUBS_DIAGONAL";

export const RESET_RESULTS = "@@simulation/RESET_RESULTS";
export const RESET_ROTATIONS = "@@simulation/RESET_ROTATIONS";
export const RESET_BONUS = "@@simulation/RESET_BONUS";
export const RESET_TACTICS = "@@simulation/RESET_TACTICS";
export const RESET_SUBSTITUTIONS = "@@simulation/RESET_SUBSTITUTIONS";
