import React from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

import { colours } from "../utils/colours";

const Panel = (props) => {
  const {
    boxSub1A,
    boxSub2A,
    boxSub1B,
    boxSub2B,
    boxCha1A,
    boxCha2A,
    boxCha1B,
    boxCha2B,
    boxRot1A,
    boxRot2A,
    boxRot1B,
    boxRot2B,
    boxTec1A,
    boxTec2A,
    boxTec1B,
    boxTec2B,
    boxTac1A,
    boxTac2A,
    boxTac1B,
    boxTac2B,
    boxSub1AHandler,
    boxSub2AHandler,
    boxSub1BHandler,
    boxSub2BHandler,
    boxCha1AHandler,
    boxCha2AHandler,
    boxCha1BHandler,
    boxCha2BHandler,
    boxRot1AHandler,
    boxRot2AHandler,
    boxRot1BHandler,
    boxRot2BHandler,
    boxTec1AHandler,
    boxTec2AHandler,
    boxTec1BHandler,
    boxTec2BHandler,
    boxTac1AHandler,
    boxTac2AHandler,
    boxTac1BHandler,
    boxTac2BHandler,
  } = props;

  const GreenCheckbox = withStyles({
    root: {
      color: colours.greyDark,
      "&$checked": {
        color: colours.mainDark,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <Grid container item xs direction="column" spacing={1}>
      <Grid container item xs alignItems="center">
        <Grid item xs />
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxRot1A} onChange={boxRot1AHandler} />
            <GreenCheckbox checked={boxRot2A} onChange={boxRot2AHandler} />
          </center>
        </Grid>
        <Grid item xs={4}>
          <center>
            <font size="+1">CAMBIO ROTAZIONI</font>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxRot1B} onChange={boxRot1BHandler} />
            <GreenCheckbox checked={boxRot2B} onChange={boxRot2BHandler} />
          </center>
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container item xs alignItems="center">
        <Grid item xs />
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxSub1A} onChange={boxSub1AHandler} />
            <GreenCheckbox checked={boxSub2A} onChange={boxSub2AHandler} />
          </center>
        </Grid>
        <Grid item xs={4}>
          <center>
            <font size="+1">CAMBIO GIOCATORE</font>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxSub1B} onChange={boxSub1BHandler} />
            <GreenCheckbox checked={boxSub2B} onChange={boxSub2BHandler} />
          </center>
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container item xs alignItems="center">
        <Grid item xs />
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxCha1A} onChange={boxCha1AHandler} />
            <GreenCheckbox checked={boxCha2A} onChange={boxCha2AHandler} />
          </center>
        </Grid>
        <Grid item xs={4}>
          <center>
            <font size="+1">SOSTITUZIONE TATTICA</font>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxCha1B} onChange={boxCha1BHandler} />
            <GreenCheckbox checked={boxCha2B} onChange={boxCha2BHandler} />
          </center>
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container item xs alignItems="center">
        <Grid item xs />
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxTec1A} onChange={boxTec1AHandler} />
            <GreenCheckbox checked={boxTec2A} onChange={boxTec2AHandler} />
          </center>
        </Grid>
        <Grid item xs={4}>
          <center>
            <font size="+1">BONUS TECNICO</font>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxTec1B} onChange={boxTec1BHandler} />
            <GreenCheckbox checked={boxTec2B} onChange={boxTec2BHandler} />
          </center>
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container item xs alignItems="center">
        <Grid item xs />
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxTac1A} onChange={boxTac1AHandler} />
            <GreenCheckbox checked={boxTac2A} onChange={boxTac2AHandler} />
          </center>
        </Grid>
        <Grid item xs={4}>
          <center>
            <font size="+1">SCELTA TATTICA</font>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <GreenCheckbox checked={boxTac1B} onChange={boxTac1BHandler} />
            <GreenCheckbox checked={boxTac2B} onChange={boxTac2BHandler} />
          </center>
        </Grid>
        <Grid item xs />
      </Grid>
    </Grid>
  );
};

export default Panel;
