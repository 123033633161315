import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const InputField = ({
  id,
  value,
  onChange,
  onBlur = () => {},
  dispatchAction = false,
  dispatchType = undefined,
  color = "primary",
  variant = "standard",
  fullWidth = true,
  multiline = false,
  readOnly = false,
  label = "",
  placeholder = "",
  helperText = "",
  errorText = "Il valore inserito non è valido.",
  errorFunc = () => {
    return false;
  },
  size = "medium",
  type = "text",
}) => {
  // state to manage the input validation status
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  return (
    <TextField
      id={id}
      value={value || ""}
      color={color}
      variant={variant}
      error={!!error}
      fullWidth={!!fullWidth}
      multiline={!!multiline}
      label={label}
      placeholder={placeholder}
      onChange={(event) => {
        !!dispatchAction
          ? dispatch({ type: dispatchType, payload: event.target.value })
          : onChange && onChange(event.target.value);
      }}
      onBlur={(event) => {
        errorFunc && setError(errorFunc(event.target.value));
        (errorFunc &&
          !errorFunc(event.target.value) &&
          onBlur(event.target.value)) ||
          onBlur(event.target.value);
      }}
      helperText={(!!error && errorText) || helperText}
      size={size}
      type={type}
      InputProps={{
        readOnly: !!readOnly,
      }}
    />
  );
};

InputField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  dispatchAction: PropTypes.bool,
  dispatchType: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  errorText: PropTypes.string,
  errorFunc: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  type: PropTypes.oneOf(["email", "password", "text"]),
};

export default InputField;
