export const colours = {
  mainLight: "rgba(70, 235, 52, 0.6)",
  main: "rgba(70, 235, 52, 0.8)",
  mainDark: "rgba(70, 235, 52, 1.0)",
  white: "rgba(255, 255, 255, 1.0)",
  whiteGrey: "rgba(190, 190, 190, 0.2)",
  greyLight: "rgba(190, 190, 190, 0.6)",
  grey: "rgba(190, 190, 190, 0.8)",
  greyDark: "rgba(190, 190, 190, 1.0)",
  blackLight: "rgba(0, 0, 0, 0.7)",
  black: "rgba(0, 0, 0, 1.0)",
  greenLight: "rgba(30, 160, 30, 0.6)",
  green: "rgba(30, 160, 30, 0.8)",
  greenDark: "rgba(30, 160, 30, 1.0)",
  redLight: "rgba(255, 0, 0, 0.5)",
  red: "rgba(255, 0, 0, 0.7)",
  redDark: "rgba(255, 0, 0, 1.0)",
  orangeLight: "rgba(255, 150, 50, 0.5)",
  orange: "rgba(255, 150, 50, 0.75)",
  orangeDark: "rgba(255, 150, 50, 1.0)",
  yellowLight: "rgba(255, 255, 0, 0.3)",
  yellow: "rgba(255, 255, 0, 0.5)",
  yellowDark: "rgba(255, 255, 0, 1.0)",
};
