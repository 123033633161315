import React from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";
import { Dialog, DialogContent } from "@material-ui/core";

import { colours } from "../utils/colours";

const SixResults = ({ open, handleClose, data }) => {
  const Nx =
    (!!data.sixResults &&
      Object.values(data.sixResults).reduce((a, b) => a + b, 0)) ||
    0;
  const xValues = (!!data.sixResults && Object.keys(data.sixResults)) || [];
  const yValues =
    (!!data.sixResults &&
      Object.values(data.sixResults).map((i) => Math.round((i / Nx) * 100))) ||
    [];
  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        {(!!data.sixResults && (
          <Plot
            data={[
              {
                type: "bar",
                x: xValues,
                y: yValues,
                text: yValues.map((yValue) => yValue > 0 && `${yValue}%`),
                textposition: "outside",
                marker: {
                  color: [
                    colours.greenDark,
                    colours.green,
                    colours.greenLight,
                    colours.redLight,
                    colours.red,
                    colours.redDark,
                  ],
                },
              },
            ]}
            layout={{
              title: `${data.description || "Nessuna descrizione"}${
                (Nx === 1 && " (prova rapida)") || ""
              }`,
              margin: { t: 50, b: 30, l: 30, r: 30 },
              autosize: true,
              paper_bgcolor: colours.white,
              plot_bgcolor: colours.white,
              yaxis: { visible: false },
              hovermode: false,
              dragmode: false,
            }}
            config={{ displayModeBar: false }}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
          />
        )) || <h4>Dettaglio non disponibile.</h4>}
      </DialogContent>
    </Dialog>
  );
};

SixResults.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.exact({
    description: PropTypes.string,
    sixResults: PropTypes.objectOf(PropTypes.number),
  }),
};

export default SixResults;
