import {
  RESET_PLAYERS,
  RESET_SELECTED,
  UPDATE_AVAILABLE_PLAYERS,
  UPDATE_AVAILABLE_SETTERS,
  UPDATE_AVAILABLE_OPPOSITES,
  UPDATE_AVAILABLE_MIDDLES,
  UPDATE_AVAILABLE_HITTERS,
  UPDATE_AVAILABLE_LIBEROS,
  UPDATE_SELECTED_SETTER_A,
  UPDATE_SELECTED_OPPOSITE_A,
  UPDATE_SELECTED_MIDDLE1_A,
  UPDATE_SELECTED_MIDDLE2_A,
  UPDATE_SELECTED_HITTER1_A,
  UPDATE_SELECTED_HITTER2_A,
  UPDATE_SELECTED_LIBERO_A,
  UPDATE_SELECTED_SETTER_B,
  UPDATE_SELECTED_OPPOSITE_B,
  UPDATE_SELECTED_MIDDLE1_B,
  UPDATE_SELECTED_MIDDLE2_B,
  UPDATE_SELECTED_HITTER1_B,
  UPDATE_SELECTED_HITTER2_B,
  UPDATE_SELECTED_LIBERO_B,
  UPDATE_AVAILABLE_TEAMS,
  UPDATE_SELECTED_TEAM_A,
  UPDATE_SELECTED_TEAM_B,
} from "./types";

const INIT_STATE = {
  availablePlayers: {},
  availableSetters: {},
  availableOpposites: {},
  availableMiddles: {},
  availableHitters: {},
  availableLiberos: {},
  availableTeams: [],
};

export const playersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_PLAYERS:
      return INIT_STATE;
    case RESET_SELECTED:
      return {
        ...state,
        selectedSetterA: undefined,
        selectedOppositeA: undefined,
        selectedMiddle1A: undefined,
        selectedMiddle2A: undefined,
        selectedHitter1A: undefined,
        selectedHitter2A: undefined,
        selectedLiberoA: undefined,
        selectedSetterB: undefined,
        selectedOppositeB: undefined,
        selectedMiddle1B: undefined,
        selectedMiddle2B: undefined,
        selectedHitter1B: undefined,
        selectedHitter2B: undefined,
        selectedLiberoB: undefined,
        selectedTeamA: undefined,
        selectedTeamB: undefined,
      };
    case UPDATE_AVAILABLE_PLAYERS:
      return {
        ...state,
        availablePlayers: {
          ...state.availablePlayers,
          [action.payload.championship]: action.payload.players,
        },
      };
    case UPDATE_AVAILABLE_SETTERS:
      return {
        ...state,
        availableSetters: {
          ...state.availableSetters,
          [action.payload.championship]: action.payload.players,
        },
      };
    case UPDATE_AVAILABLE_OPPOSITES:
      return {
        ...state,
        availableOpposites: {
          ...state.availableOpposites,
          [action.payload.championship]: action.payload.players,
        },
      };
    case UPDATE_AVAILABLE_MIDDLES:
      return {
        ...state,
        availableMiddles: {
          ...state.availableMiddles,
          [action.payload.championship]: action.payload.players,
        },
      };
    case UPDATE_AVAILABLE_HITTERS:
      return {
        ...state,
        availableHitters: {
          ...state.availableHitters,
          [action.payload.championship]: action.payload.players,
        },
      };
    case UPDATE_AVAILABLE_LIBEROS:
      return {
        ...state,
        availableLiberos: {
          ...state.availableLiberos,
          [action.payload.championship]: action.payload.players,
        },
      };
    case UPDATE_SELECTED_SETTER_A:
      return { ...state, selectedSetterA: action.payload };
    case UPDATE_SELECTED_OPPOSITE_A:
      return { ...state, selectedOppositeA: action.payload };
    case UPDATE_SELECTED_MIDDLE1_A:
      return { ...state, selectedMiddle1A: action.payload };
    case UPDATE_SELECTED_MIDDLE2_A:
      return { ...state, selectedMiddle2A: action.payload };
    case UPDATE_SELECTED_HITTER1_A:
      return { ...state, selectedHitter1A: action.payload };
    case UPDATE_SELECTED_HITTER2_A:
      return { ...state, selectedHitter2A: action.payload };
    case UPDATE_SELECTED_LIBERO_A:
      return { ...state, selectedLiberoA: action.payload };
    case UPDATE_SELECTED_SETTER_B:
      return { ...state, selectedSetterB: action.payload };
    case UPDATE_SELECTED_OPPOSITE_B:
      return { ...state, selectedOppositeB: action.payload };
    case UPDATE_SELECTED_MIDDLE1_B:
      return { ...state, selectedMiddle1B: action.payload };
    case UPDATE_SELECTED_MIDDLE2_B:
      return { ...state, selectedMiddle2B: action.payload };
    case UPDATE_SELECTED_HITTER1_B:
      return { ...state, selectedHitter1B: action.payload };
    case UPDATE_SELECTED_HITTER2_B:
      return { ...state, selectedHitter2B: action.payload };
    case UPDATE_SELECTED_LIBERO_B:
      return { ...state, selectedLiberoB: action.payload };
    case UPDATE_AVAILABLE_TEAMS:
      return { ...state, availableTeams: action.payload };
    case UPDATE_SELECTED_TEAM_A:
      return { ...state, selectedTeamA: action.payload };
    case UPDATE_SELECTED_TEAM_B:
      return { ...state, selectedTeamB: action.payload };
    default:
      return state;
  }
};
