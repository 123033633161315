import {
  RESET_SIMULATION,
  UPDATE_SIMULATIONID,
  UPDATE_DESCRIPTION,
  UPDATE_STATUS,
  UPDATE_NX,
  UPDATE_DIVIDE_BY_ROLE,
  UPDATE_IS_PLAYABLE,
  UPDATE_RISULTATO,
  UPDATE_PARZIALI,
  UPDATE_TABELLINO,
  UPDATE_SUBS_LOG,
  UPDATE_SERVES_A,
  UPDATE_TEAM_A_ROTS,
  UPDATE_TEAM_B_ROTS,
  UPDATE_TEAM_A_BONUS,
  UPDATE_TEAM_B_BONUS,
  UPDATE_TEAM_A_TACTICS_VARIABLES,
  UPDATE_TEAM_A_TACTICS_VALUES,
  UPDATE_TEAM_B_TACTICS_VARIABLES,
  UPDATE_TEAM_B_TACTICS_VALUES,
  UPDATE_TEAM_A_SUBS_SERVICE,
  UPDATE_TEAM_B_SUBS_SERVICE,
  UPDATE_TEAM_A_SUBS_DIAGONAL,
  UPDATE_TEAM_B_SUBS_DIAGONAL,
  RESET_TEAM_A_SUBS_SERVICE,
  RESET_TEAM_B_SUBS_SERVICE,
  RESET_TEAM_A_SUBS_DIAGONAL,
  RESET_TEAM_B_SUBS_DIAGONAL,
  RESET_RESULTS,
  RESET_ROTATIONS,
  RESET_BONUS,
  RESET_TACTICS,
  RESET_SUBSTITUTIONS,
} from "./types";

const INIT_STATE = {
  simulationID: undefined,
  description: undefined,
  status: "new",
  Nx: undefined,
  divideByRole: true,
  isPlayable: false,
  risultato: undefined,
  parziali: undefined,
  tabellino: undefined,
  subsLog: undefined,
  servesA: true,
  teamArotations: [1, 1, 1, 1, 1],
  teamBrotations: [1, 1, 1, 1, 1],
  teamAbonus: "no",
  teamBbonus: "no",
  teamAtacticsVariables: "",
  teamAtacticsValues: "",
  teamBtacticsVariables: "",
  teamBtacticsValues: "",
  teamAsubsService: { when: undefined, in: undefined, out: undefined },
  teamBsubsService: { when: undefined, in: undefined, out: undefined },
  teamAsubsDiagonal: {
    when: undefined,
    in1: undefined,
    out1: undefined,
    in2: undefined,
    out2: undefined,
  },
  teamBsubsDiagonal: {
    when: undefined,
    in1: undefined,
    out1: undefined,
    in2: undefined,
    out2: undefined,
  },
};

export const simulationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_SIMULATION:
      return { ...INIT_STATE, mode: state.mode };
    case UPDATE_SIMULATIONID:
      return { ...state, simulationID: action.payload };
    case UPDATE_DESCRIPTION:
      return { ...state, description: action.payload };
    case UPDATE_STATUS:
      return { ...state, status: action.payload };
    case UPDATE_NX:
      return { ...state, Nx: action.payload };
    case UPDATE_DIVIDE_BY_ROLE:
      return { ...state, divideByRole: action.payload };
    case UPDATE_IS_PLAYABLE:
      return { ...state, isPlayable: action.payload };
    case UPDATE_RISULTATO:
      return { ...state, risultato: action.payload };
    case UPDATE_PARZIALI:
      return { ...state, parziali: action.payload };
    case UPDATE_TABELLINO:
      return { ...state, tabellino: action.payload };
    case UPDATE_SUBS_LOG:
      return { ...state, subsLog: action.payload };
    case UPDATE_SERVES_A:
      return { ...state, servesA: action.payload };
    case UPDATE_TEAM_A_ROTS:
      return { ...state, teamArotations: action.payload };
    case UPDATE_TEAM_B_ROTS:
      return { ...state, teamBrotations: action.payload };
    case UPDATE_TEAM_A_BONUS:
      return { ...state, teamAbonus: action.payload };
    case UPDATE_TEAM_B_BONUS:
      return { ...state, teamBbonus: action.payload };
    case UPDATE_TEAM_A_TACTICS_VARIABLES:
      return { ...state, teamAtacticsVariables: action.payload };
    case UPDATE_TEAM_A_TACTICS_VALUES:
      return { ...state, teamAtacticsValues: action.payload };
    case UPDATE_TEAM_B_TACTICS_VARIABLES:
      return { ...state, teamBtacticsVariables: action.payload };
    case UPDATE_TEAM_B_TACTICS_VALUES:
      return { ...state, teamBtacticsValues: action.payload };
    case UPDATE_TEAM_A_SUBS_SERVICE:
      return { ...state, teamAsubsService: action.payload };
    case UPDATE_TEAM_B_SUBS_SERVICE:
      return { ...state, teamBsubsService: action.payload };
    case UPDATE_TEAM_A_SUBS_DIAGONAL:
      return { ...state, teamAsubsDiagonal: action.payload };
    case UPDATE_TEAM_B_SUBS_DIAGONAL:
      return { ...state, teamBsubsDiagonal: action.payload };
    case RESET_TEAM_A_SUBS_SERVICE:
      return { ...state, teamAsubsService: INIT_STATE.teamAsubsService };
    case RESET_TEAM_B_SUBS_SERVICE:
      return { ...state, teamBsubsService: INIT_STATE.teamBsubsService };
    case RESET_TEAM_A_SUBS_DIAGONAL:
      return { ...state, teamAsubsDiagonal: INIT_STATE.teamAsubsDiagonal };
    case RESET_TEAM_B_SUBS_DIAGONAL:
      return { ...state, teamBsubsDiagonal: INIT_STATE.teamBsubsDiagonal };
    case RESET_RESULTS:
      return {
        ...state,
        risultato: INIT_STATE.risultato,
        parziali: INIT_STATE.parziali,
        tabellino: INIT_STATE.tabellino,
        subsLog: INIT_STATE.subsLog,
      };
    case RESET_ROTATIONS:
      return {
        ...state,
        teamArotations: INIT_STATE.teamArotations,
        teamBrotations: INIT_STATE.teamBrotations,
      };
    case RESET_BONUS:
      return {
        ...state,
        teamAbonus: INIT_STATE.teamAbonus,
        teamBbonus: INIT_STATE.teamBbonus,
      };
    case RESET_TACTICS:
      return {
        ...state,
        teamAtacticsVariables: INIT_STATE.teamAtacticsVariables,
        teamAtacticsValues: INIT_STATE.teamAtacticsValues,
        teamBtacticsVariables: INIT_STATE.teamBtacticsVariables,
        teamBtacticsValues: INIT_STATE.teamBtacticsValues,
      };
    case RESET_SUBSTITUTIONS:
      return {
        ...state,
        teamAsubsService: INIT_STATE.teamAsubsService,
        teamBsubsService: INIT_STATE.teamBsubsService,
        teamAsubsDiagonal: INIT_STATE.teamAsubsDiagonal,
        teamBsubsDiagonal: INIT_STATE.teamBsubsDiagonal,
      };
    default:
      return state;
  }
};
