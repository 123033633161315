import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const Tabellino = props => {
  const { tabellino, open, handleClose } = props

  const colNames = [
    'Giocatore',
    'Punti',
    'Battute',
    'Errori',
    'Ace',
    'Rice',
    'Ace',
    'Positive',
    'Perfette',
    'Attacchi',
    'Errori',
    'Punti',
    '%',
    'Muri',
    'Punti',
    'Difese',
  ]

  const zeroChar = ''

  const formatInt = x => {
    return x === 0 || x === '0' ? zeroChar : parseInt(x)
  }

  const formatSum = (x, y, z) => {
    return (x === 0 && y === 0 && z === 0) ||
      (x === '0' && y === '0' && z === '0')
      ? zeroChar
      : parseInt(x) + parseInt(y) + parseInt(z)
  }

  const formatRec = x => {
    return x === 0 || x === '0' ? zeroChar : Math.round(parseInt(x * 100)) + '%'
  }

  const formatAtt = (x, y) => {
    return x === 0 || y === 0 || x === '0' || y === '0'
      ? zeroChar
      : Math.round((parseInt(x) / parseInt(y)) * 100) + '%'
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleClose}
      aria-describedby='tabellino'
    >
      <DialogContent>
        <Table id='tabellino' size='small'>
          <TableHead>
            <TableRow>
              {colNames.map((c, i) => (
                <TableCell
                  key={i}
                  align='center'
                  style={{ borderBottom: '1px solid' }}
                >
                  <font>
                    <b>{c}</b>
                  </font>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabellino &&
              tabellino.length > 0 &&
              tabellino.map((p, i) => (
                <TableRow
                  key={i}
                  style={{
                    borderBottom: p['Player'] === 'Ateam' ? '2px solid' : '',
                  }}
                >
                  <TableCell
                    align='center'
                    style={{ borderRight: '1px solid' }}
                  >
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>Squadra</b>
                      ) : (
                        p['Player']
                      )}
                    </font>
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ borderRight: '1px solid' }}
                  >
                    <font>
                      <b>{formatSum(p['Ser#'], p['Att#'], p['Blo#'])}</b>
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['SerTot'])}</b>
                      ) : (
                        formatInt(p['SerTot'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['Ser='])}</b>
                      ) : (
                        formatInt(p['Ser='])
                      )}
                    </font>
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ borderRight: '1px solid' }}
                  >
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['Ser#'])}</b>
                      ) : (
                        formatInt(p['Ser#'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['RecTot'])}</b>
                      ) : (
                        formatInt(p['RecTot'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['Rec='])}</b>
                      ) : (
                        formatInt(p['Rec='])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatRec(p['Rec+'])}</b>
                      ) : (
                        formatRec(p['Rec+'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ borderRight: '1px solid' }}
                  >
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatRec(p['Rec#'])}</b>
                      ) : (
                        formatRec(p['Rec#'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['AttTot'])}</b>
                      ) : (
                        formatInt(p['AttTot'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['Att='])}</b>
                      ) : (
                        formatInt(p['Att='])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['Att#'])}</b>
                      ) : (
                        formatInt(p['Att#'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ borderRight: '1px solid' }}
                  >
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatAtt(p['Att#'], p['AttTot'])}</b>
                      ) : (
                        formatAtt(p['Att#'], p['AttTot'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['BloTot'])}</b>
                      ) : (
                        formatInt(p['BloTot'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ borderRight: '1px solid' }}
                  >
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['Blo#'])}</b>
                      ) : (
                        formatInt(p['Blo#'])
                      )}
                    </font>
                  </TableCell>
                  <TableCell align='center'>
                    <font>
                      {p['Player'] === 'Ateam' || p['Player'] === 'Bteam' ? (
                        <b>{formatInt(p['DigTot'])}</b>
                      ) : (
                        formatInt(p['DigTot'])
                      )}
                    </font>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}

export default Tabellino
