import {
  RESET_GLOBAL,
  UPDATE_USER,
  UPDATE_AUTH,
  UPDATE_ERROR,
  UPDATE_MODE,
  UPDATE_AVAILABLE_CHAMPIONSHIPS,
  UPDATE_AVAILABLE_CHAMPIONSHIP_NAMES,
  UPDATE_SELECTED_CHAMPIONSHIP,
  UPDATE_DEFAULT_NX,
} from './types'

const INIT_STATE = {
  auth: false,
  defaultNx: 100,
  availableChampionships: [],
  availableChampionshipNames: [],
}

export const globalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_GLOBAL:
      return INIT_STATE
    case UPDATE_USER:
      return { ...state, user: action.payload }
    case UPDATE_AUTH:
      return { ...state, auth: action.payload }
    case UPDATE_ERROR:
      return { ...state, error: action.payload }
    case UPDATE_MODE:
      return { ...state, mode: action.payload }
    case UPDATE_DEFAULT_NX:
      return { ...state, defaultNx: action.payload }
    case UPDATE_AVAILABLE_CHAMPIONSHIPS:
      return { ...state, availableChampionships: action.payload }
    case UPDATE_AVAILABLE_CHAMPIONSHIP_NAMES:
      return { ...state, availableChampionshipNames: action.payload }
    case UPDATE_SELECTED_CHAMPIONSHIP:
      return { ...state, selectedChampionship: action.payload }
    default:
      return state
  }
}
