import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

import { colours } from "../utils/colours";

const Tactic = ({
  tactAvar,
  tactAval,
  tactBvar,
  tactBval,
  selectedTeamA,
  selectedTeamB,
  open,
  handleClose,
  tactAHandler,
  tactBHandler,
}) => {
  const GreenCheckbox = withStyles({
    root: {
      color: colours.greyDark,
      "&$checked": {
        color: colours.mainDark,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const tactics = [
    "Rischio in battuta",
    "Prudenza in battuta",
    "Battuta verso 1",
    "Battuta verso 6",
    "Battuta verso 5",
    "Rischio in attacco",
    "Prudenza in attacco",
    "Distribuzione in zona 2",
    "Distribuzione in zona 3",
    "Distribuzione in zona 4",
    "Nessuno",
  ].map((b, i) => (
    <Grid item xs key={i}>
      <center>
        <b>{b}</b>
      </center>
    </Grid>
  ));

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-describedby="tactic"
    >
      <DialogContent>
        <Grid container item xs alignItems="center">
          <Grid item xs>
            <center>
              <h3>{selectedTeamA || "Squadra A"}</h3>
            </center>
          </Grid>
          <Grid item xs>
            <center>
              <h3>{selectedTeamB || "Squadra B"}</h3>
            </center>
          </Grid>
        </Grid>
        &nbsp;
        <Grid container item xs>
          <Grid container item xs direction="column" spacing={2}>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("serrisk") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("serrisk")
                    ] === "high"
                  }
                  onChange={() => tactAHandler("serrisk", "high")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("serrisk") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("serrisk")
                    ] === "low"
                  }
                  onChange={() => tactAHandler("serrisk", "low")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("serto") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("serto")
                    ] === "1"
                  }
                  onChange={() => tactAHandler("serto", "1")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("serto") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("serto")
                    ] === "6"
                  }
                  onChange={() => tactAHandler("serto", "6")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("serto") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("serto")
                    ] === "5"
                  }
                  onChange={() => tactAHandler("serto", "5")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("attrisk") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("attrisk")
                    ] === "high"
                  }
                  onChange={() => tactAHandler("attrisk", "high")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("attrisk") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("attrisk")
                    ] === "low"
                  }
                  onChange={() => tactAHandler("attrisk", "low")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("setto") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("setto")
                    ] === "2"
                  }
                  onChange={() => tactAHandler("setto", "2")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("setto") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("setto")
                    ] === "3"
                  }
                  onChange={() => tactAHandler("setto", "3")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactAvar.split("&").includes("setto") &&
                    tactAval.split("&")[
                      tactAvar.split("&").indexOf("setto")
                    ] === "4"
                  }
                  onChange={() => tactAHandler("setto", "4")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={tactAvar === "" || tactAval === ""}
                  onChange={() => tactAHandler("no", "no")}
                />
              </center>
            </Grid>
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            {tactics}
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("serrisk") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("serrisk")
                    ] === "high"
                  }
                  onChange={() => tactBHandler("serrisk", "high")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("serrisk") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("serrisk")
                    ] === "low"
                  }
                  onChange={() => tactBHandler("serrisk", "low")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("serto") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("serto")
                    ] === "1"
                  }
                  onChange={() => tactBHandler("serto", "1")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("serto") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("serto")
                    ] === "6"
                  }
                  onChange={() => tactBHandler("serto", "6")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("serto") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("serto")
                    ] === "5"
                  }
                  onChange={() => tactBHandler("serto", "5")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("attrisk") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("attrisk")
                    ] === "high"
                  }
                  onChange={() => tactBHandler("attrisk", "high")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("attrisk") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("attrisk")
                    ] === "low"
                  }
                  onChange={() => tactBHandler("attrisk", "low")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("setto") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("setto")
                    ] === "2"
                  }
                  onChange={() => tactBHandler("setto", "2")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("setto") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("setto")
                    ] === "3"
                  }
                  onChange={() => tactBHandler("setto", "3")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={
                    tactBvar.split("&").includes("setto") &&
                    tactBval.split("&")[
                      tactBvar.split("&").indexOf("setto")
                    ] === "4"
                  }
                  onChange={() => tactBHandler("setto", "4")}
                />
              </center>
            </Grid>
            <Grid item xs>
              <center>
                <GreenCheckbox
                  checked={tactBvar === "" || tactBval === ""}
                  onChange={() => tactBHandler("no", "no")}
                />
              </center>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  tactAvar: state.simulation.teamAtacticsVariables,
  tactAval: state.simulation.teamAtacticsValues,
  tactBvar: state.simulation.teamBtacticsVariables,
  tactBval: state.simulation.teamBtacticsValues,
  selectedTeamA: state.players.selectedTeamA,
  selectedTeamB: state.players.selectedTeamB,
});

const ConnectedTactic = connect(mapStateToProps)(Tactic);

export default ConnectedTactic;
