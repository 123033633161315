import React from "react";
import Grid from "@material-ui/core/Grid";

const LineupSelection = (props) => {
  const { autoPlayers, spacing } = props;

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={spacing || 5}
    >
      {autoPlayers}
    </Grid>
  );
};

export default LineupSelection;
