import styled from "styled-components";

import { colours } from "../utils/colours";

const Top = styled.div`
  height: 5px;
  width: 100%;
  background: linear-gradient(
    90deg,
    ${colours.black} 0%,
    ${colours.mainDark} 40%,
    ${colours.mainDark} 60%,
    ${colours.black} 100%
  );
`;

export default Top;
