import React from "react";
import { useDispatch, connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  UPDATE_TEAM_A_ROTS,
  UPDATE_TEAM_B_ROTS,
} from "../container/simulation/types";

const Rotations = ({
  selectedTeamA,
  selectedTeamB,
  teamArotations,
  teamBrotations,
  servesA,
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const setsID = [1, 2, 3, 4, 5];

  const sets = setsID.map((set, i) => (
    <Grid item xs key={i}>
      <center>
        <b>{set}° set</b>
      </center>
    </Grid>
  ));

  const autoRotationsA = setsID.map((set) => (
    <Grid item xs key={set}>
      <Autocomplete
        fullWidth
        options={[1, 2, 3, 4, 5, 6]}
        getOptionLabel={(option) => "P" + option}
        value={teamArotations[set - 1] || null}
        onChange={(_, value) => {
          dispatch({
            type: UPDATE_TEAM_A_ROTS,
            payload: [
              ...teamArotations.slice(0, set - 1),
              value,
              ...teamArotations.slice(set, teamArotations.length),
            ],
          });
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Grid>
  ));

  const autoRotationsB = setsID.map((set) => (
    <Grid item xs key={set}>
      <Autocomplete
        fullWidth
        options={[1, 2, 3, 4, 5, 6]}
        getOptionLabel={(option) => "P" + option}
        value={teamBrotations[set - 1] || null}
        onChange={(_, value) => {
          dispatch({
            type: UPDATE_TEAM_B_ROTS,
            payload: [
              ...teamBrotations.slice(0, set - 1),
              value,
              ...teamBrotations.slice(set, teamBrotations.length),
            ],
          });
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Grid>
  ));

  const startsA = setsID.map((set) => (
    <Grid item xs key={set}>
      {((servesA && set % 2 === 1) || (!servesA && set % 2 === 0)) && (
        <center>Battuta</center>
      )}
    </Grid>
  ));

  const startsB = setsID.map((set, i) => (
    <Grid item xs key={i}>
      {((!servesA && set % 2 === 1) || (servesA && set % 2 === 0)) && (
        <center>Battuta</center>
      )}
    </Grid>
  ));

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-describedby="rotations"
    >
      <DialogContent>
        <Grid container item xs alignItems="center">
          <Grid item xs>
            <center>
              <h3>{selectedTeamA || "Squadra A"}</h3>
            </center>
          </Grid>
          <Grid item xs>
            <center>
              <h3>{selectedTeamB || "Squadra B"}</h3>
            </center>
          </Grid>
        </Grid>
        &nbsp;
        <Grid container item xs>
          <Grid container item xs direction="column" spacing={2}>
            {autoRotationsA}
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            {startsA}
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            {sets}
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            {startsB}
          </Grid>
          <Grid container item xs direction="column" spacing={2}>
            {autoRotationsB}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  selectedTeamA: state.players.selectedTeamA,
  selectedTeamB: state.players.selectedTeamB,
  teamArotations: state.simulation.teamArotations,
  teamBrotations: state.simulation.teamBrotations,
  servesA: state.simulation.servesA,
});

const ConnectedRotations = connect(mapStateToProps)(Rotations);

export default ConnectedRotations;
