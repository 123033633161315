import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Simulation from './pages/Simulation'

const App = ({ auth }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact>
          <Home />
        </Route>
        {auth && (
          <>
            <Route key='dashboard' path='/dashboard' exact>
              <Dashboard />
            </Route>
            <Route key='simulation' path='/simulation' exact>
              <Simulation />
            </Route>
          </>
        )}
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = state => ({
  auth: state.global.auth,
})

const ConnectedApp = connect(mapStateToProps)(App)

export default ConnectedApp
